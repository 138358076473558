.fancy-logo {
    svg {
        width: 502px;
        height: 279px;
        margin-left: -150px;

        @include media-breakpoint-up(md) {
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            width: 938px;
            height: 520px;
        }
    }
}