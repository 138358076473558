* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  &.admin-bar {
    .site-header {
      margin-top: 32px;
    }
  }

  &.menu-active {
    overflow: hidden;
  }
}

html, body {
  font-size: 16px;
}

#main {
  padding: 0;
}

.cover-background {
  @include position(absolute, 0, 0, 0, 0);
  z-index: -2;

  &.overlay::after {
    content: '';
    @include position(absolute, 0, 0, 0, 0);
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

/**
 * Responsive Video Embeds 
 */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/**
 * Remove Mobile IOS Styles
 */
input, textarea, button {
  -webkit-appearance: none;
  -webkit-border-radius:0px;
}

// Font Colours
.color {
	&--primary {
		color: map-get($theme-colors, primary) !important;
  }
  &--primary-a {
		color: map-get($theme-colors, primary-a) !important;
  }
  &--primary-b {
		color: map-get($theme-colors, primary-b) !important;
  }
  &--primary-c {
		color: map-get($theme-colors, primary-c) !important;
	}
	&--secondary {
		color: map-get($theme-colors, secondary) !important;
  }
  &--secondary-a {
		color: map-get($theme-colors, secondary-a) !important;
  }
  &--secondary-b {
		color: map-get($theme-colors, secondary-b) !important;
	}
	&--tertiary {
		color: map-get($theme-colors, tertiary) !important;
  }
  &--dark-primary {
    color: map-get($theme-colors, dark-primary) !important;
  }
  &--dark-secondary {
    color: map-get($theme-colors, dark-secondary) !important;
  }
  &--dark-disabled {
    color: map-get($theme-colors, dark-disabled) !important;
  }
  &--light-primary {
    color: map-get($theme-colors, light-primary) !important;
  }
  &--light-secondary {
    color: map-get($theme-colors, light-secondary) !important;
  }
  &--light-disabled {
    color: map-get($theme-colors, light-disabled) !important;
  }
	&--white {
		color: $white !important;
	}
}
// Font Colours -- END

// BG Colours
.bg {
	&--primary {
		background-color: map-get($theme-colors, primary) !important;
  }
  &--primary-a {
		background-color: map-get($theme-colors, primary-a) !important;
  }
  &--primary-b {
		background-color: map-get($theme-colors, primary-b) !important;
  }
  &--primary-c {
		background-color: map-get($theme-colors, primary-c) !important;
	}
	&--secondary {
		background-color: map-get($theme-colors, secondary) !important;
  }
  &--secondary-a {
		background-color: map-get($theme-colors, secondary-a) !important;
  }
  &--secondary-b {
		background-color: map-get($theme-colors, secondary-b) !important;
	}
	&--tertiary {
		background-color: map-get($theme-colors, tertiary) !important;
	}
	&--white {
		background-color: $white;
	}
}
// BG Colours -- END

// Plugin custom styling - Instagram feed
.instgarm_wrapper {
  .instagram-gallery__actions {
    margin: 0;
  }
  .instagram-gallery-feed.loaded {
    .instagram-gallery-item, 
    .instagram-gallery-image, 
    .instagram-gallery__actions {
      margin: 0 !important;
    }
  }
  .instagram-gallery-item:hover .instagram-gallery-item__media-mask {
    opacity: 0.5;
  }
  .qligg-icon-instagram::before {
    color: $white;
  }
  .swiper-button-next::after, 
  .swiper-button-prev::after {
    color: $white;
  }
  .swiper-pagination-bullet-active {
    background: map-get($theme-colors, primary);
  }
}
