// Fancybox Global Styles
.fancybox-content {
    width: 90%;
    height: auto;
    background: $transparent;
    overflow: hidden;
    position: initial;
    padding: 0 !important;

    @include media-breakpoint-up(xl) {
        width: 80%;
        height: 80%;
    }
    
    button {
        background-color: map-get($theme-colors, primary-b);
        color: $white !important;
        position: absolute;
        top: 0;
        bottom: 0;
    }


    iframe {
        width: 100%;
        height: auto;
        min-height: 480px;

        @include media-breakpoint-up(lg) {
            height: 100%;
        }
    }
}