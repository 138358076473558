.chamfer-block {
    overflow: hidden;

    &--full-width {
        width: 100%;
        max-height: 375px;
    }

    &--generic-block {
        width: 328px;
        height: 335px;
    }

    &--case-study-tease {
        width: 320px;
        height: 190px;
    }

    &--large-block {
        width: 495px;
        height: 335px;
    }
    
    &--team-medium {
        width: 198px;
        height: 242px;
        
        &.has-hover-image {
            position: relative;
            cursor: pointer;
            transition: all .5s ease-in-out;

            &:hover {
                .mask {
                    &.main-image {
                        z-index: -1;
                        opacity: 0;
                    }
                    &.alternate-image {
                        z-index: 10;
                        opacity: 1;
                    }
                }
            }
        }

    }

    &--team-small {
        width: 130px;
        height: 176px;
        
        &.has-hover-image {
            position: relative;
            cursor: pointer;
            transition: all .5s ease-in-out;

            &:hover {
                .mask {
                    &.main-image {
                        z-index: -1;
                        opacity: 0;
                    }
                    &.alternate-image {
                        z-index: 10;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &--related-articles {
        width: 233px;
        height: 335px;
    }
}
.mask {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    object-fit: cover;
    mask-mode: alpha;
    mask-repeat: no-repeat;
    mask-size: cover;
    padding: 1px;
    transition: all .5s ease-in-out;

    &--full-width {
        width: 100%;
        max-height: 375px;
        mask-image: url('../images/image-masks/cta.svg');
    }

    &--generic-block {
        width: 100%;
        height: 100%;
        mask-image: url('../images/image-masks/service-block.svg');

        @include media-breakpoint-up(lg) {
            mask-position: right;
        }

        @include media-breakpoint-up(xl) {
            mask-position: top;
        }
    }

    &--case-study-tease {
        width: 100%;
        height: 100%;
        mask-image: url('../images/image-masks/case-study-tease.svg');
    }

    &--team-medium {
        width: 100%;
        height: 100%;
        mask-image: url('../images/image-masks/team-col2.svg');

        &.has-hover-image {
            position: relative;
            transition: all .5s ease-in-out;

            &.main-image,
            &.alternate-image {
                transition: all .5s ease-in-out;
                position: absolute;
                top: 0;
                left: 0;
            }

            &.main-image {
                z-index: 10;
                opacity: 1;
            }
            &.alternate-image {
                z-index: -1;
                opacity: 0;
            }
        }
    }

    &--team-small {
        width: 100%;
        height: 100%;
        mask-image: url('../images/image-masks/team-col3.svg');

        &.has-hover-image {
            position: relative;
            transition: all .5s ease-in-out;

            &.main-image,
            &.alternate-image {
                transition: all .5s ease-in-out;
                position: absolute;
                top: 0;
                left: 0;
            }

            &.main-image {
                z-index: 10;
                opacity: 1;
            }
            &.alternate-image {
                z-index: -1;
                opacity: 0;
            }
        }
    }

    &--large-block {
        width: 100%;
        height: 100%;
        mask-image: url('../images/image-masks/image-2col-block.svg');
        mask-position: right;

        @include media-breakpoint-up(md) {
            mask-position: initial;
        }

        @include media-breakpoint-up(lg) {
            mask-position: top;
        }
    }

    &--related-articles {
        width: 100%;
        height: 100%;
        mask-image: url('../images/image-masks/single-news-slide.svg');
        mask-position: right;
    }
}
