.feature-post-block {
    position: relative;
    overflow: hidden;
    transition: all .25s ease-in-out;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 328px;

    &:hover {
        cursor: pointer;
        transform: translate3d(0, -1rem, 0);
        
        .feature-post-block {
            &__link {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &__tag {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
    &__content {
        position: absolute;
        bottom: 0;
        padding: 2rem;
        width: calc(100% - 1px);
        background: linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(0,0,0,.9) 100%);
        min-height: calc(89px + 4rem);

        .title {}
        .blurb {}
    }
    &__link {
        position: absolute;
        bottom: 0;
        right: 1px;
        width: 70px;
        height: 100px;
        background-image: url('../images/corner-triangle.svg');
        @include bg-image(contain, bottom, no-repeat);
        margin: 0 -1px -1px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .25s ease-in-out;
        transform: translate3d(0, 200%, 0);

        .icon {
            color: $white;
            position: absolute;
            bottom: .5rem;
            right: 1rem;
        }

        &:hover {
        }
    }

    .chamfer-block {
        z-index: -1;
        position: relative;
        top: 0;
        left: 0;
        // width: calc(100% - -1px);
    }
}