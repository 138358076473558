/* ==========================================================================
    Navigation Styles
   ========================================================================== */

.site-header {
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.62) 25.6%, rgba(255, 255, 255, 0) 100%, rgba(0, 0, 0, 0) 100%);
  background-blend-mode: multiply;
  transition: all .25s ease-in-out;

  &.scrolled {
    @include media-breakpoint-up(lg) {
      transform: translate3d(0, -34px, 0);
    }

    .site-header {
      &__bottom {
        background-color: rgba(map-get($theme-colors, primary-b), .94);

        @include media-breakpoint-up(xl) {
          background-color: map-get($theme-colors, primary-b);
        }
        .primary-navigation__wrap {
          padding: 5px 0;
          transition: all .25s ease-in-out;

          .brand-logo {
            
            img {
              @include media-breakpoint-up(xl) {
                padding: .5rem 0;
                width: 60%;
              }
            }
          }
        }
      }
    }
  }

  &__bottom {
    background-color: rgba(map-get($theme-colors, primary-b), .94);
    height: 105px;

    @include media-breakpoint-up(xl) {
      background-color: $transparent;
      height: auto;
    }

    .primary-navigation__wrap {
      height: 100%;

      .brand-logo {
        padding: 0;
        width: 67px;
        height: 47px;

        @include media-breakpoint-up(xl) {
          width: 100%;
          height: 100%;
        }
      }

      >.container {
        height: 100%;

        .row {
          height: 100%;
        }
      }

      @include media-breakpoint-up(xl) {
        height: auto;
      }

      .btn-cta {
        a.btn {
          display: flex;
          align-items: center;
          flex-direction: row;
          padding: 10px 26px;
          
          .icon {
            padding-right: 17px;
            padding-right: 1.0625rem;
            @include font-size(18);

          }
        }
      }
    }
  }


  &__top {
    background-color: map-get($theme-colors, primary-b);
    opacity: 0.6;

    .social-media {
      &__link {
        color: map-get($theme-colors, primary-a);
        transition: all .25s ease-in-out;

        &:hover {
          opacity: .6;
        }
      }
    }
  }

  &__navigation {
    height: 93px;
  }

  .site-menu {
    &__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 auto;
      color: $white;
      width: 44px;
      height: 44px;
      @include font-size(25);
    }
  }
}


/* ==========================================================================
    General Navigation Styles
   ========================================================================== */
.nav {

  .heading {
    list-style: none;
    padding-bottom: 19px;
    margin: 19px 19px 0 19px;
    position: relative;
    
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid map-get($theme-colors, secondary-a);
    }

    a {
      @include defaultH4();
      font-weight: bold;
      color: map-get($theme-colors, primary-b);
      transition: all .25s ease-in-out;

      .icon {
        margin-left: .75rem;
        transition: all .25s ease-in-out;
      }

      &:hover {
        text-decoration: none;
        opacity: .7;

        .icon {
          margin-left: 1rem;
        }
      }
    }
  }

  &-list {
    &__item {
    }
    &__link {
      transition: all .25s ease-in-out;
      color: $white;

      &:hover {
        color: map-get($theme-colors, primary-a);
        text-decoration: none;
      }

      &.current-menu-item {
        color: map-get($theme-colors, primary-a);
      }
    }

    &--dropdown {
      .nav-list {
        z-index: 5;

        &__item {
        }
        &__link {
          color: map-get($theme-colors, primary-a);
        }
      }

      .nav-list__item {
        
      }
    }
  }

  /* Primary Navigation
   ========================================================================== */
  &--primary {
    height: 100%;

    .nav {
      &-list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
        margin: 0;

        &__item {
          list-style-type: none;
          margin: 0 calc(38px /2);
          position: relative;
          display: flex;
          align-items: center;

          /**
           * Move dropdown menu to right of last menu to account for page-overflow,
           * could probably alter this to nth-child(x+) for of the list is actually
           * greather than nth amount of items.
           */

          &:last-of-type {
            .nav-drop {
              right: 0;
              left: initial;
            }
          }

          &:hover {
            .nav-list--dropdown-wrapper {
              opacity: 1;
              pointer-events: all;
            }
          }
        }

        &__link {
          @include defaultH5();
          margin-bottom: 0;
          position: relative;
        }

        &--dropdown-wrapper {
          position: absolute;
          top: 100%;
          left: 0;
          padding: 42.5px 0 0;
          z-index: 10;
          min-width: 300px;
          opacity: 0;
          pointer-events: none;
          transition: all .25s ease-in-out;

          &.mega-menu {
            width: 467px;
          }
        }

        &--dropdown {
          background-color: $white;
          box-shadow: 0px 4px 8px rgba($black, 0.1);
          padding: 30px;

          &.normal-menu {
            .nav-list {
              &__item {
                margin-top: 0;
                margin-bottom: 0;
              }
              &__link {
                &.current_page_item {
                  color: map-get($theme-colors, primary-b);
                }
                .link-text {
                  margin-bottom: 0;
                }
              }
            }
          }
          
          .nav-list {
            &__item {
              margin-top: calc(27px / 2);
              margin-bottom: calc(27px / 2);

              &:first-child {
                margin-top: 0;
              }
              &:last-child {
                margin-bottom: 0;
              }

              .caption {
                color: map-get($theme-colors, dark-disabled);
              }
            }

            &__link {
              text-align: left;
              display: inline-flex;
              flex-direction: column;
              padding: 15px 0;
              margin-bottom: 0;

              .link-text {
                margin-bottom: 7px;
              }
            }
          }
        }
      }
    }
  }

  /* Primary Sub Navigation
  ========================================================================== */
  &--sub-primary {
    .nav-list {
      list-style-type: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      padding: 0;
      margin: .25rem 0;
      
      &__item {
        position: relative;
        padding: 0 7.5px;
        &:after {
          content: "";
          height: 15px;
          border-right: 1px solid hsla(0,0%,100%,.75);
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }

      &__link {
        @include font-size(14);
        padding: 0px 4px;
        color: $white;
      }
    }
  }

  /* Mobile Navigation
   ========================================================================== */
  &--mobile {
    @include transform(translate3d(500%, 0, 0));
    position: fixed;
    top: 0;
    right: 0;

    height: 100vh;
    width: $mobile-menu-width;
    margin: 0 0 0 auto;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 30px;
    z-index: 99999999;
    transition: transform $menu-animation-timing;
    background-color: map-get($theme-colors, primary-b);

    .mobile-links-wrapper {
      margin-top: 50px;
      margin-bottom: 50px;
      height: calc(100% - 100px);
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .nav-list {
      overflow: scroll;

      &.nav-list--dropdown {
        overflow: hidden;
      }
    }

    .nav-list {    
      height: 100%;
      padding-bottom: 2.5rem;
      
      &__level {
        ul.nav-list--dropdown {
          li.nav-list__item {
            a.nav-list__link {
              font-family: $font-family-secondary;
              color: $white;
              border: 0;
              margin-top: 0;
              margin-bottom: 0;
              @include font-size(15);
              width: 100%;
            }
          }
        }
      }
      &__item {
        &.top-level {
          &:first-of-type {
            a.nav-list__link {
              margin-top: 0;
            }
          }
          &:last-of-type {
            a.nav-list__link {
              border: 0;
            }
          }
        }
      }
      &__link {
        @include font-size(15);
        display: block;
        padding: .5rem 0;
        width: 70%;
        font-family: $font-family-primary;
        border-bottom: 1px solid rgba(map-get($theme-colors, secondary-a), .15);
        margin-top: 50px;
        margin-bottom: 1rem;
        
        > .icon {
          margin-left: .25rem;
        }
      }
    }

    &-list {
      

      &__item {
      }
      &__link {
        padding: 10px 0;
        border-bottom: 1px solid map-get($theme-colors, secondary-a);
        
      }

      &__level {
        @include position(absolute, 0, null, null, 0);
        @include transform(translate3d(-100%, 0, 0));

        @include pseudo(after, "") {
          @include position(absolute, 0, 0, null, null);
          opacity: 0;
        }

        height: 100%;
        width: 100%;
      }

      &--dropdown & {
        &__item {
        }
        &--link {
        }
      }
    }
  }
}

/* ==========================================================================
   Mobile Navigation Setup // Styles
   ========================================================================== */

html,
body,
.main,
.site--wrapper {
  height: 100%;
}

.site--wrapper {
  position: relative;

  &::after {
    content: '';
    @include position(absolute, 0, 0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    z-index: 90;
    opacity: 0;
    transition: opacity $menu-animation-timing;
    pointer-events: none;
  }
}

.mobile-navigation { 
  &__wrapper {
    position: relative;
  }

  &__push {
    position: relative;
    z-index: 99999999;
    background-color: rgba($black, 0.5);
  }

  &__controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__exit {
    @include font-size(16);
    width: 140px;
    height: 140px;
    min-width: 30px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    z-index: 99;
    pointer-events: all;
    background-image: url('../images/mobile-close-bg.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    .icon {
      position: absolute;
      top: 30px;
      right: 30px;
      @include font-size(22);
    }
  }

  &__logo {
    position: relative;

    img {
      width: 200px;
    }
  }
}

.main {
  position: relative;
  overflow: hidden;

  &.menu-active {
    .nav--mobile {
      position: fixed;
      z-index: 99999999;
      @include transform(translate3d(0px, 0, 0));
    }

    .mobile-navigation { 
      &__push {
        transition: all .25s ease-in-out;
      }
    }
  }
}
