.page-builder--feature-post-with-cta {
    .container {
        z-index: 2;
        position: relative;
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: auto;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__cta-block {
        padding: 1rem;
        width: calc(100% - 27px);
        height: calc(100% - 27px);
        margin: calc(26px / 2);
        background-image: url(../images/image-masks/review.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top right;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-right: -1px;
    }

    &__item {
        width: calc(100% - 26px);
        height: calc(100% - 26px);
        margin: calc(26px / 2);   
    }

    .feature-post-block {
        width: 100%;
        height: 100%;

        &__content {
            padding: 1rem;

            @include media-breakpoint-up(md) {
                padding: 2rem;
            }
        }
        
        .chamfer-block {
            width: 100%;
            height: 100%;
            
            @include media-breakpoint-up(md) {
                .mask {
                    mask-position: top;
                }
            }

            @include media-breakpoint-up(lg) {
                height: 335px;
            }
        }
    }

    // Slick Slider Styles
    .pb-feature-post-block-slider {
        .slick-list {
            padding: 0 25px !important;
        }
        .slick-track {
            display: flex !important;
            margin: 1rem 0;
            @include media-breakpoint-up(xl) {
                width: auto !important;
            }
        }

        .slick-slide {
            height: inherit !important;
            width: calc(395px - 26px) !important;

            @include media-breakpoint-up(xl) {
                width: 33.33%;
            }            
            
            > div {
                height: 100%;

                .slide {
                    height: 100%;                    
                }
            }
        }

        .slider-controls {
            display: flex;
            justify-content: flex-end;

            .slick-arrow {
                cursor: pointer;
                display: inline-flex !important;
                justify-content: center;
                align-items: center;
                margin: .5rem;
                min-width: 40px;
                min-height: 40px;
                border-radius: 20px;
                border: 2px solid rgba($black, .25);
                color: rgba($black, .25);
                line-height: 1rem;
                transition: all .25s ease-in-out;

                &:hover {
                    background-color: rgba($black, .25);
                    color: $white;
                }
            }
        }
    }

    // CTA Float Left
    &.cta-float-left {
        .pb-feature-post-block-slider {
            .slick-slide {
                &:nth-child(3) {
                    order: -1;
                }
            }
        }
    }
}