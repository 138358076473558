.case-study-tease {
    width: 100%;
    transition: all .25s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: translate3d(0, -1rem, 0);
    }

    &__feature-image {
        position: relative;

        .case-study-logo {
            z-index: 2;
            position: absolute;
            bottom: 2rem;
            left: 1rem;

            img {
                width: 80%;
            }
        }

        .chamfer-block {
            width: 100%;
        }

        a.cover-link {
            z-index: 2;
        }
    }

    &__content {
        @extend .text-medium;
        color: map-get($theme-colors, dark-secondary);
        margin-top: 1rem;
    }
}