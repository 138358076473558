aside {

    .sidebar-cta-block {
        background-image: url('../images/image-masks/news-single-cta.svg');
        @include bg-image(cover, top, no-repeat);
        padding: 2.5rem 2rem;
        border-radius: 5px;
        min-height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .related-posts {
        margin-top: 3rem;

        .related-posts-slider-container {
            overflow: hidden;

            .slick-slide {
                outline: 0;
            }

            .slider-dots {
                width: 100%;
                display: inline-flex;
                justify-content: center;

                ul.slick-dots {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: inline-flex;
                    justify-content: center;
                    
                    li {
                        display: flex;
                        align-content: center;
                        padding: .25rem;

                        &.slick-active,
                        &:hover {
                            button {
                                background-color: rgba($black, .25);
                                border-color: $transparent;
                            }
                        }

                        button {
                            padding: 0;
                            font-size: 0;
                            width: 13px;
                            height: 13px;
                            border: 2px solid rgba($black, .25);
                            background-color: $transparent;
                            border-radius: 7px;
                            transition: all .5s ease-in-out;

                            &:focus, &:active,  {
                                outline: 0;
                                background-color: rgba($black, .25);
                                border-color: $transparent;
                            }
                        }
                    }
                }
            }
        }

        &__item {
            position: relative;
            margin: .5rem;

            .content {
                z-index: 1;
                position: absolute;
                bottom: 1px;
                width: 100%;
                background: linear-gradient(180deg,hsla(0,0%,100%,0),rgba(0,0,0,.9));
                padding: 1rem;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;

                .post-title {
                    margin-bottom: 0;
                }
            }
            .chamfer-block {
                width: 100%;
            }
        }
    }

    .share-links-block {
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding: 2rem;
        background-color: map-get($theme-colors, secondary-a);
        
        .share-btn {
            a.share-btn {
                @include font-size(24);
                color: map-get($theme-colors, primary-a);
                margin: .5rem;
                transition: all .25s ease-in-out;

                &:hover {
                    cursor: pointer;
                    color: map-get($theme-colors, primary-b);
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
