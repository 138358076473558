.page-builder--standard-block {
    &__video-preview {
        width: 100%;
        height: 100%;
        @include bg-image(cover, center, no-repeat);

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            padding-bottom: 70%;
        }

        a.video-trigger {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $white;
            font-size: 6rem;

        }
    }

    .standard-block-content {
        padding-top: 2.5rem;
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    .slider-container {
        .slider {
            overflow: hidden;
        }
        
        .slider-controls {
            margin-top: 2rem;
            text-align: right;
            
            .slick-arrow {
                margin: 0 .5rem;
                font-size: 1.5rem;
            }
        }

        .slider-controls {
            display: flex;
            justify-content: center;
            margin-top: calc(20px - .5rem);
            margin-bottom: calc(20px - .5rem);

            @include media-breakpoint-up(lg) {
                margin-top: calc(40px - .5rem);
                margin-bottom: calc(40px - .5rem);
            }

            .slick-arrow {
                cursor: pointer;
                display: inline-flex !important;
                justify-content: center;
                align-items: center;
                margin: .5rem;
                min-width: 40px;
                min-height: 40px;
                border-radius: 20px;
                border: 2px solid rgba($black, .25);
                color: rgba($black, .25);
                line-height: 1rem;
                transition: all .25s ease-in-out;

                &:hover {
                    background-color: rgba($black, .25);
                    color: $white;
                }
            }
        }
    }

    .logo-blocks {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        
        &__item {
            width: 33.33%;
            display: flex;
            align-self: center;
            justify-content: center;
            padding: .5rem;

            a {
                width: 100%;
            }

            img {
                align-self: baseline;
            }
        }
    }

    .standard-block-links {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: .5rem;
        
        &__item {
            width: 100%;
            transition: all .25s ease-in-out;
            padding-bottom: 5%;

            @include media-breakpoint-up(md) {
                width: 50%;
                padding-right: 5%;
                padding-bottom: 5%;
            }

            &:hover {
                text-decoration: none;
                
                .heading {
                    .icon {
                        padding-left: .75rem;
                    }
                }
                .blurb {
                    opacity: .6;
                }
            }
            .heading {
                width: 100%;
                display: block;
                transition: all .25s ease-in-out;
                
                .text {
                    @include font-size(12);
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .icon {
                    padding-left: .25rem;
                    transition: all .25s ease-in-out;
                }
            }
            .blurb {
                width: 100%;
                @include font-size(14);
                color: $black;
                transition: all .25s ease-in-out;
                color: rgba($black, .5);
            }
        }
    }
    
    .standard-block-link-cta {
        margin: 0 auto 0 0;
    }
}

// .fancybox-can-pan .fancybox-content,
// .fancybox-can-swipe .fancybox-content {
//     width: 60%;
//     padding-top: 8rem;
//     padding-bottom: 8rem;

//     iframe {
//         width: 100%;
//         @include media-breakpoint-up(lg) {
//             height: 650px;
//         }
//     }
// }

// .fancybox-content {
//     background-color: $transparent;
// }

// .fancybox-button {
//     color: $white !important;
//     width: 60px;
//     height: 60px;
// }