/**
 * Footer base styles
 * @see footer.twig
 */
   
.footer {
    padding-top: 5rem;
    background-color: map-get($theme-colors, primary-b);

    &.sticky-menu-offset {
        margin-bottom: 75px;
    }

    &__top {
        .footer-brand-logo {           
            img {
                width: 111px;
                height: 81px;
            }
        }

        .footer-title {
            color: $white;
            position: relative;
            width: 100%;
            margin-bottom: 0;
            height: 44px;
            display: flex;
            align-items: center;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 2rem;
            margin-bottom: 2rem;

            li {
                padding: .15rem 0;
                @extend .text-big;

                a {
                    color: $white;
                    text-decoration: none;
                    transition: all .25s ease-in-out;

                    .icon {
                        margin-right: 0.75rem;
                    }

                    &:hover {
                        opacity: .6;
                    }
                }
            }
        }
    }
    &__newsletter {
        &:before {
            content: '';
            display: block;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid rgba(map-get($theme-colors, secondary-a), .15);
        }
    }
    &__bottom {
        background-color: #000f36;
        padding: 24px 0;
        color: rgba($white, .5);

        .footer-brand-logo {
            display: block;          
            img {
                width: 73px;
                height: 53px;
            }
        }

        .copyright-text {
            p, a {
                color: rgba($white, .5);
                
            }
            a {
                &:hover {
                    text-decoration: none;
                    color: $white;
                }
            }
        }

        a {
            color: rgba($white, .5);
            transition: all .25s ease-in-out;
           
            &:hover {
                text-decoration: none;
                color: $white;
            }
        }

        .social-icons {
            .social-media {
                &__item {}
                &__link {
                    @include font-size(18);
                    color: $white;
                }
            }

            @include media-breakpoint-up(lg) {
                margin-left: 1rem;
            }
        }
    }
}

.footer-accordion {
    // transition: all .5s ease-in-out;
    &.force-open {
        .footer-accordion {
            &__content {
                display: block;
            }
        }
    }
    &.active {
        // transition: all .5s ease-in-out;
        .footer-accordion {
            &__header {
                .footer-accordion-icon {
                    i {
                        transform: rotate(-180deg);
                    }
                }
            }
            &__content {
                // height: 100%;
                // visibility: visible;
                // opacity: 1;
            }
        }
    }
    &__header {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            display: block;
            border-bottom: 1px solid rgba(map-get($theme-colors, secondary-a), .15);
        }

        .footer-accordion-icon {
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 2;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 44px;
            height: 44px;
            text-decoration: none;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

    }
    &__content {
        // height: 0;
        // visibility: hidden;
        // opacity: 0;
        // transition: height .5s ease-in-out, visibility .5s ease-in-out;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block !important;
            // height: 100%;
            // visibility: visible;
            // opacity: 1;
        }
    }
}