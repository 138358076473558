// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/*
 *  Gravity Forms Example Form Styles
 */

.two-col {
  display: inline-flex;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  
  .ginput_container {
    width: 100%;
  }
}


.gform_footer {
  button {
    cursor: pointer;
  }
}

.gform_wrapper li.hidden_label textarea {
  margin-top: 12px;
}


// Page Builder Content Form Styles
.gform_wrapper {
  form {
    .gform_body {
      .gform_fields {
        .gfield {
          .ginput_container {
            margin-top: 0;
          }

          input[type=text],
          textarea {
            border: 0;
            font-size: 14px !important;
            border-bottom: 4px solid map-get($theme-colors, primary);
            background-color: $transparent;
            margin-top: 0;
            min-height: 50px;
            padding: 17px 8px !important;

            @include media-breakpoint-up(md) {
              font-size: 18px !important;
              font-size: 1.125rem !important;
            }

            &:focus {
              outline: 0;
            }
          }

          textarea {
            padding: 14px 8px;
            margin-top: 0;
          }

          .ginput_container_date {
            width: 100%;
            border-bottom: 4px solid #017bc4;
            
            input[type=text] {
              width: 90%;
              border-bottom: 0;

              @media (min-width: 375px) {
                &::placeholder {
                  white-space: pre-line;
                  position: relative;
                  top: -10px;
                }
              }

              @media (min-width: 425px) {
                &::placeholder {
                  white-space: pre-line;
                  position: relative;
                  top: 0;
                }
              }
            }
          }
        }

      }


    }
    .gform_footer {
      input[type=submit] {
        @extend .btn;
        @extend .btn-primary;
      }
    }
    .gform_footer {
      display: flex;
      align-items: center;

      input[type=submit] {
        position: relative;
        margin: 0;
        transition: all .5s ease-in-out;
      }

      input[type=submit]:hover + span:after,
      input[type=submit]:focus + span:after {
        color: $white;
      }

      span.icon {
        height: 37px;
        width: 20px;
        display: inline-flex;
        justify-content: center;
        margin-left: -50px;

        @include media-breakpoint-up(lg) {
          margin-left: -30px;
        }

        &:after {
          display: flex;
          align-items: center;
          color: map-get($theme-colors, primary-b);
          content: "\f178";
          font-family: "Font Awesome 5 Pro";
          font-size: 20px;
          z-index: 2;
          transition: all .5s ease-in-out;
        }
      }
    }
  }
}

// Calendar Widget Styles
.ui-datepicker {
  &-header {
    background-color: map-get($theme-colors, primary-b) !important;
    text-shadow: 0 0 0 $transparent;
    border-color: map-get($theme-colors, primary-b) !important;
  }

  th {
    color: $white !important;
    text-shadow: 0 0 0 $white !important;
  }

  tbody {
    td {
      border-color: map-get($theme-colors, primary-b) !important;

      &.ui-datepicker-today {
        a {
          margin: 0;
          background: map-get($theme-colors, primary-b) !important;
          border: 0 solid $white;
          color: $white !important;
          box-shadow: inset 0 0 0 0 $transparent;
          text-shadow: 0 0 0 $white !important;
          margin: 0 !important;
          border: 0 solid $transparent !important;
        }
      }
    }
  }
}

.ui-datepicker-calendar .ui-state-default {
  background: $white !important;
  box-shadow: inset 0 0 0 0 $white !important;
}

.ui-datepicker-calendar .ui-state-active {
  margin: 0;
  background: map-get($theme-colors, primary-a) !important;
  border: 0 solid $white;
  color: $white !important;
  box-shadow: inset 0 0 0 0 $transparent;
  text-shadow: 0 0 0 $white !important;
  margin: 0 !important;
  border: 0 solid $transparent !important;
}

.ui-datepicker thead {
  background: map-get($theme-colors, primary-b) !important;
}

td.ui-datepicker-unselectable.ui-state-disabled {
  background-color: $white !important;
}
// Calendar Widget Styles -- END

// Newsletter Form Styles
.newsletter-form_wrapper {
  margin: 0;
}

.gform_wrapper {
  form.newsletter-form {
    position: relative;
    .gform_body {
      .gform_fields {
        display: inline-flex;
        width: 100%;

        .gfield {
          width: 50%;
          margin: 0;

          .ginput_container  {
            margin: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }

          input {
            margin: 0;
            color: $white;
            background-color: $transparent !important;
            &::placeholder {
              color: $white;
            }
            
          }
        }      
      }
    }
    .gform_footer {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 0;
      margin: 0;
      
      input.gform_button {
        width: 44px !important;
        height: 44px !important;
        padding: .85rem !important;
        margin-bottom: 0 !important;
        outline: 0 !important;
        transition: all .25s ease-in-out;
        &:hover {
          opacity: .7;
        }
      }

      .icon {
        display: none;
      }
    }
  }
}

// Single News Stories page - Newsletter Form
#gform_wrapper_5 {
  form {
    .gform_footer {
      text-align: center;
    }
  }
}

// Careers Form Styles
.careers-form_wrapper {
  margin: 0;
}

.gform_wrapper {
  margin: 0;
  
  form.careers-form {
    .gform_body {
      .gform_fields {
        display: flex;
        flex-wrap: wrap;

        .gfield {
          width: 100%;
          margin: 0;

          @include media-breakpoint-up(lg) {
            width: 50%;
          }

          .ginput_container {
            margin: 0;

            input[type=text], .gfield_select {
              margin: calc(38px / 2) 0;
            }
          }
        }
      }

      .custom-file-upload-field {
        display: flex;
        justify-content: space-between;
        height: 40px;
        position: relative;
        margin: 19px 0 !important;

        .ginput_container_fileupload {
          position: absolute;
          right: 16px;
          height: 40px;
        }

        label {
          margin: 0;
          display: flex !important;
          align-items: center;
          width: 100%;
          font-weight: normal !important;
          border-bottom: 4px solid map-get($theme-colors, primary);
          padding: 0 4px;
        }

        input[type=file] {
          width: 40px !important;
          height: 40px;

          &::-webkit-file-upload-button {
            visibility: hidden;
          }

          &::before {
            content: '\f093';
            font-family: "Font Awesome 5 Pro";
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
            cursor: pointer;
            font-weight: light;
            font-size: 10pt;
            height: 100%;
            width: 100%;
          }
        }

      }
    }
    .gform_footer {
      display: flex;
      align-items: center;

      input[type=submit] {
        position: relative;
        margin: 0;
        transition: all .5s ease-in-out;
      }

      input[type=submit]:hover + span:after,
      input[type=submit]:focus + span:after {
        color: $white;
      }

      span.icon {
        height: 37px;
        width: 20px;
        display: inline-flex;
        justify-content: center;
        margin-left: -50px;

        @include media-breakpoint-up(lg) {
          margin-left: -30px;
        }

        &:after {
          display: flex;
          align-items: center;
          color: map-get($theme-colors, primary-b);
          content: "\f178";
          font-family: "Font Awesome 5 Pro";
          font-size: 20px;
          z-index: 2;
          transition: all .5s ease-in-out;
        }
      }
    }
  }
}
