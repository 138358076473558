.feature-case-study-block {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
    &__feature-image {
        position: relative;

        .chamfer-block--large-block {
            width: 100%;
            height: 250px;

            img.mask {
                mask-position: top;
            }

            @include media-breakpoint-up(md) {
                height: 335px;
            } 

            @include media-breakpoint-up(lg) {
                width: 495px;
                height: 335px;
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
    }
    &__brand-logo {
        max-width: 100px;
        margin-bottom: 1rem;
    }

}