.block {
}

.hero-slider {
  .slide {
    position: relative;
    padding: 70px 15px;

    &-inner {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-up(lg) {
        min-height: 450px;
      }
    }

    .cover-background {
      @include position(absolute, 0, 0, 0, 0);
      z-index: -2;

      &::after {
        content: "";
        @include position(absolute, 0, 0, 0, 0);
        background: rgba(0, 0, 0, 0.7);
        z-index: -1;
      }
    }
  }
}

// Careers - Archive Page
body.post-type-archive-careers {

  .apply-for-job-block {
    padding: 102px 0 120px 0;
  }

  .page-builder--slider-content-block {
    &__slider {
      .slick-slide {
        height: auto !important;

        @include media-breakpoint-up(md) {
          max-height: 330px;
        }
        // @include media-breakpoint-up(lg) {
        //   height: 380px !important;
        // }
        &.left,
        &.right {
          &:before,
          &:after {
            background: none;
          }
        }
      }
    }
  }
}

// Front Page Styles

body.page-template-template-front-page {
  .page-builder-blocks{
    .page-builder {
      &:nth-child(1) {
        margin-top: -25%;

        @include media-breakpoint-up(xl) {
          margin-top: -20%;
        }
        @media (min-width: 1400px) {
          margin-top: -12.5%;
        }
      }
    }
  }
}


//Overlap First Page Builder block

// Basic Page - With Overlap Option Toggled
body.content-overlap {
  .standard-page-header {
    padding-bottom: 25%;
    min-height: 500px;

    @include media-breakpoint-up(md) {
      min-height: 650px;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 20%;
    }

    @media (min-width: 1400px) {
      padding-bottom: 12.5%;
    }
  }

  .page-builder-blocks {
    .page-builder {
      &:nth-child(1) {
        &.page-builder--feature-post-slider {
          margin-top: -25%;

          @include media-breakpoint-up(xl) {
            margin-top: -20%;
          }
          @media (min-width: 1400px) {
            margin-top: -12.5%;
          }
        }
      }
    }
  }
}
// Basic Page - With Overlap Option Toggled -- END