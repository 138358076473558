.page-builder--slider-content-block {
    padding: calc(3.75rem *2) 0;

    &__slider {
        .slick-slide {
            width: 245px !important;
            height: 347px !important;
            margin: .5rem;

            @include media-breakpoint-up(md) {
                width: 347px !important;
                height: 347px !important;
            }

            @include media-breakpoint-up(lg) {
                width: 400px !important;
                height: 450px !important;
            }

            @include media-breakpoint-up(xl) {
                width: 461px !important;
                height: 540px !important;
                margin: calc(37px / 2);
            }

            .slide-image {
                @include bg-image(cover, center, no-repeat);
                height: 100%;
                width: 100%;
                border-radius: 4px;
                
                &:after {
                    content: '';
                    display: block;
                    padding-bottom: 141.632%;

                    @include media-breakpoint-up(md) {
                        padding-bottom: 100%;
                    }

                    @include media-breakpoint-up(lg) {
                        padding-bottom: 112.5%;
                    }

                    @include media-breakpoint-up(xl) {
                        padding-bottom: 117.39%;
                    }
                }
            }
        }

        .slider-controls {
            display: flex;
            justify-content: center;
            margin-top: calc(20px - .5rem);
            margin-bottom: calc(20px - .5rem);

            @include media-breakpoint-up(lg) {
                margin-top: calc(40px - .5rem);
                margin-bottom: calc(40px - .5rem);
            }

            .slick-arrow {
                cursor: pointer;
                display: inline-flex !important;
                justify-content: center;
                align-items: center;
                margin: .5rem;
                min-width: 40px;
                min-height: 40px;
                border-radius: 20px;
                border: 2px solid rgba($black, .25);
                color: rgba($black, .25);
                line-height: 1rem;
                transition: all .25s ease-in-out;

                &:hover {
                    background-color: rgba($black, .25);
                    color: $white;
                }
            }
        }

        &.left {
            .slick-list {

                &:after {
                    right: 0;
                    top: 0;
                    background: linear-gradient(-90deg, $white, $transparent);
                }

                @include media-breakpoint-up(lg) {
                    &:after {
                        background: none;
                    }
                }

                &:before {
                    left: 0;
                    top: 0;
                    background: linear-gradient(90deg, $white, $transparent);
                }
            }
        }
        &.right {
            .slick-list {

                &:before {
                    left: 0;
                    top: 0;
                    background: linear-gradient(90deg, #fff, $transparent);
                }

                @include media-breakpoint-up(lg) {
                    &:before {
                        background: none;
                    }
                }

                &:after {
                    right: 0;
                    top: 0;
                    background: linear-gradient(-90deg, #fff, $transparent);
                }
            }
        }

        .slick-list {
            &:before, &:after {
                content: '';
                width: 15%;
                height: 100%;
                display: block;
                position: absolute;
                z-index: 2;
            }
        }
    }
    &__content {
        &.left {            
            width: 90%;
            margin: 0 auto;

            @include media-breakpoint-up(xl) {
                width: 80%;
                margin-left: auto;
                margin-right: 0;
            }

            @media (min-width: 1600px) {
                width: calc(62.5% - calc(70px - 15px));
                margin-left: auto;
                margin-right: 0;
                padding-right: 70px;
            }
        }
        &.right {
            width: 90%;
            margin: 0 auto;

            @include media-breakpoint-up(xl) {
                width: 80%;
                margin-right: auto;
                margin-left: 0;
            }

            @media (min-width: 1600px) {
                width: calc(62.5% - calc(70px - 15px));
                margin-right: auto;
                margin-left: 0;
                padding-left: 70px;
            }
        }

        .icon-grid {
            &--item {
                display: flex;
                flex-direction: row;
                padding: 1rem 0;
                .icon {
                    margin-right: 2rem;
                    min-width: 50px;
                    max-width: 50px;
                    height: auto;
                }
                .content {
                    &__heading {
                        margin-bottom: .5rem;
                        .h4 {
                            color: map-get($theme-colors, primary-b);
                        }
                    }
                    &__blurb {}
                }
            }
        }
    }
}