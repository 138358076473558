$headings: $h1-font-size, $h2-font-size, $h3-font-size, $h4-font-size,
$h5-font-size, $h6-font-size;

@font-face {
    font-family: 'Forza';
    src: url('../fonts/Forza-Bold.eot');
    src: local('Forza-Bold'),
        url('../fonts/Forza-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Forza-Bold.woff2') format('woff2'),
        url('../fonts/Forza-Bold.woff') format('woff'),
        url('../fonts/Forza-Bold.ttf') format('truetype'),
        url('../fonts/Forza-Bold.svg#Forza-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@mixin defaultFont() {
	font-family: $font-family-secondary;
	font-weight: 400;
}
@mixin defaultHeadingFont() {
	@include defaultFont();
	font-family: 'Forza';
}

@mixin defaultParagraphFont() {
	@include defaultFont();
	font-style: normal;
	font-weight: 400;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
  color: map-get($theme-colors, primary);
  @include defaultHeadingFont();
}

body, p {
  @include defaultFont();
}

// Button Text Styles
@mixin buttonText() {
  @include font-size(15);
  font-weight: $font-weight-bold;
  font-family: $font-family-primary;
}

// Heading Styles
@mixin defaultH1() {
	@include defaultHeadingFont();
	margin-bottom: 15px;
	margin-bottom: calc-rem(15, 16);
	font-weight: $font-weight-bold;

	@include font-size(32);
	line-height: 1.25em;

	@include media-breakpoint-up(md) {
		@include font-size(40);
		line-height: 1.125em;
	}
	@include media-breakpoint-up(lg) {
		@include font-size(39);
		line-height: 1.25em;
	}
}
@mixin defaultH2() {
	@include defaultHeadingFont();
	margin-bottom: 15px;
	margin-bottom: calc-rem(15, 16);
	font-weight: $font-weight-bold;
	@include font-size(26);
	line-height: 1.15384615em;

	@include media-breakpoint-up(md) {
		@include font-size(32);
		line-height: 1.25em;
	}
	@include media-breakpoint-up(lg) {
		@include font-size(28);
		line-height: 1.25em;
	}
}
@mixin defaultH3() {
	@include defaultHeadingFont();
	margin-bottom: 15px;
	margin-bottom: calc-rem(15, 16);
	font-weight: $font-weight-bold;
	@include font-size(22);
	line-height: 1.13636364em;

	@include media-breakpoint-up(md) {
		@include font-size(24);
		line-height: 1.25em;
	}
	@include media-breakpoint-up(lg) {
		@include font-size(24);
		line-height: 1.25em;
	}
}
@mixin defaultH4() {
	@include defaultHeadingFont();
	margin-bottom: 15px;
	margin-bottom: calc-rem(15, 16);
	font-weight: $font-weight-bold;
	@include font-size(18);
	line-height: 1.11111111em;

	@include media-breakpoint-up(md) {
		@include font-size(18px);
		line-height: 1.22222222em;
	}
	@include media-breakpoint-up(lg) {
		@include font-size(19);
		line-height: 1.22222222em;
	}
}
@mixin defaultH5() {
	@include defaultHeadingFont();
	margin-bottom: 15px;
	margin-bottom: calc-rem(15, 16);
	font-weight: $font-weight-bold;

	@include font-size(16);
	line-height: 1.11111111em;

	@include media-breakpoint-up(md) {
		@include font-size(15);
		line-height: 1.22222222em;
	}
}
@mixin defaultH6() {
	@include defaultHeadingFont();
	margin-bottom: 15px;
	margin-bottom: calc-rem(15, 16);
	font-weight: $font-weight-normal;

	@include font-size(12);
	line-height: 1.11111111em;
}
@mixin defaultP() {
	@include defaultParagraphFont();
	font-weight: $font-weight-normal;
	margin-bottom: 15px;
	margin-bottom: calc-rem(15, 16);
	@include font-size(15);
	line-height: 1.45em;

	@include media-breakpoint-up(md) {
		line-height: 1.5em;
	}
}

.button-text {
  @include buttonText();
}

.special-heading {
	display: inline;
	background: map-get($theme-colors, primary);
	padding: .5rem .875rem;
	line-height: 185% !important; // increase this to increate white gap
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
	white-space: unset;
	color: map-get($theme-colors, primary-b);
}

.special-heading + p {
	margin-top: 15px;
	margin-top: calc-rem(15, 16);
}

h1,
.h1 {
	@include defaultH1();
}
h2,
.h2 {
	@include defaultH2();
}
h3,
.h3 {
	@include defaultH3();
}
h4,
.h4 {
	@include defaultH4();
}
h5,
.h5 {
	@include defaultH5();
}
h6,
.h6 {
	@include defaultH6();
}

p,
.p {
	@include defaultP();
}

a {
	@include defaultP();
	margin-bottom: 0;	
}

p {
	&:last-child {
		margin-bottom: 0;
	}

	a {
		color: $black;
		font-weight: $font-weight-bold;
		text-decoration: none;
		transition: all .25s ease-in-out;
		font-size: inherit;
		&:not(.btn) {
			&:hover {
				opacity: .6;
			}
		}
	}

	&.text-big {
		@include font-size(18);
	}
	&.text-medium {
		@include font-size(15);
	}
	&.text-small {
		@include font-size(12);
	}

	img {
		max-width: 100%;
    	height: auto;
	}
}
.p {
	&:last-child {
		margin-bottom: 0;
	}

	a {
		color: $black;
		font-weight: $font-weight-bold;
		text-decoration: none;
		transition: all .25s ease-in-out;
		font-size: inherit;
		&:hover {
			opacity: .6;
		}
	}

	&.text-big {
		@include font-size(18);
	}
	&.text-medium {
		@include font-size(15);
	}
	&.text-small {
		@include font-size(12);
	}

	img {
		max-width: 100%;
    	height: auto;
	}
}

ul {
	li {
		line-height: 150%;
		@include font-size(15);

		a {
			font-size: inherit;
		}
	}

	&.list-style-large {
		li {
			@include font-size(18);
		}
	}
	&.list-style-medium {
		li {
			@include font-size(15);
		}
	}
	&.list-style-small {
		li {
			@include font-size(12);
		}
	}
}

.text {
	&-big {
		@include font-size(18);
	}
	&-medium {
		@include font-size(15);
	}
	&-small {
		@include font-size(12);
	}
}

.special-tag {
	@include defaultHeadingFont();
	font-weight: $font-weight-bold;
	@include font-size(14);
	text-transform: uppercase;
}

strong {
	font-weight: $font-weight-bold;
}

em {
	font-style: italic;
}