.page-builder--feature-post-slider {
    &__wrapper {
        padding: 2rem 0 0 1.5rem;

        @include media-breakpoint-up(md) {
            padding: 2rem 0 0 2.75rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 3.5rem 12.39px;
        }
    }
    &__content {
        padding: 1.5rem 0;
        position: relative;
        width: 100%;
    }

    .feature-post-slider-container {
        .slick-list {
            display: flex;
        }
        .slick-track {
            width: auto !important;
            display: flex;
            justify-content: center;
        }
        .slick-slide {
            margin: calc(12.39px / 2);
            width: 328px !important;

            @include media-breakpoint-up(lg) {
                margin: calc(24.78px / 2);
            }
        }
        .slider-dots {
            width: 100%;
            display: inline-flex;
            justify-content: center;

            ul.slick-dots {
                list-style: none;
                padding: 0;
                margin: 0;
                display: inline-flex;
                justify-content: center;
                
                li {
                    display: flex;
                    align-content: center;
                    padding: .25rem;

                    &.slick-active,
                    &:hover {
                        button {
                            background-color: rgba($black, .25);
                            border-color: $transparent;
                        }
                    }

                    button {
                        padding: 0;
                        font-size: 0;
                        width: 13px;
                        height: 13px;
                        border: 2px solid rgba($black, .25);
                        background-color: $transparent;
                        border-radius: 7px;
                        transition: all .5s ease-in-out;

                        &:focus, &:active,  {
                            outline: 0;
                            background-color: rgba($black, .25);
                            border-color: $transparent;
                        }
                    }
                }
            }
        }
    }
}