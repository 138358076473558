.page-builder--cta-small {
    margin: 2rem 0;

    &__inner {
        position: relative;

        .content {
            padding: 3rem 2rem;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__subheading {
                @include defaultHeadingFont();
                @include font-size(14);
                text-transform: uppercase;
                font-weight: bold;
                color: map-get($theme-colors, primary);
                margin-bottom: 1.5rem;
            }
            &__title {
                color: $white;
                margin-bottom: 2rem;
                padding-right: 0;
            }
            &__btn {
                display: inline-flex;
                align-self: baseline;
            }
        }

        .chamfer-block {
            z-index: -2;
            position: absolute;
            top: 0;
            height: 100%;
            max-height: 100%;

            &:before {
                content: '';
                background: linear-gradient(92.68deg, #041939 50%, rgba(4, 25, 57, 0) 89.06%);
                width: 80%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                @include media-breakpoint-up(md) {
                    width: 90%;
                }

                @include media-breakpoint-up(lg) {
                    width: 100%;
                }
            }

            @include media-breakpoint-up(md) {
                max-height: 500px;
            }

            .mask {
                mask-position: right;
                height: 100%;
                max-height: 100%;

                @include media-breakpoint-up(lg) {
                    max-height: 500px;
                    padding: 0;
                    
                }

                @media (min-width: 1800px) {
                    mask-position: initial;
                }
            }
        }
    }
}