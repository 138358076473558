.page-builder--fancy-accordion-block {
    &__wrapper {
        @include media-breakpoint-up(xl) {
            position: relative;
            margin-bottom: 2.5rem;
        }
    }

}

.fancy-accordion-tabbed-content-block {
    &:before {           
        @include media-breakpoint-up(xl) {
            content: "";
            display: block;
            width: 100%;
            height: 80%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            border: 7px solid rgba(0,0,0,.1);
            border-radius: 2px;
            margin: 5rem 0;
        }
    }

    @include media-breakpoint-up(xl) {
        width: 100%;
    }

    .show-on-desktop {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &__tabs {
        width: 100%;
        display: none;
        justify-content: center;

        @media (min-width: 992px) {
            display: flex;
        }

        .tab {
            cursor: pointer;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 239px;
            min-height: 210px;
            background-color: #fff;
            margin: .5rem;
            padding: 1rem;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
            border-radius: 3px;
            position: relative;

            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: '';
                height: 6px;
                width: 0;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                background-color: map-get($theme-colors, primary-a);
                transition: all .25s ease-in-out;
            }
            
            &.active {
                box-shadow: 0px 0px 9px rgba(1, 121, 196, 0.48);

                &:after {
                    content: '';
                    height: 6px;
                    width: 100%;
                    background-color: map-get($theme-colors, primary-a);
                }

                .tab {
                    &__step {
                        .icon {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
            
            &__icon {
                margin-bottom: 1rem;
                width: 54px;
                height: 54px;
            }

            &__title {
                h4 {
                    color: map-get($theme-colors, primary-b);
                }
            }

            &__step {
                display: inline-flex;
                align-items: center;

                .step {
                    @include defaultH5();
                    font-weight: bold;
                    color: map-get($theme-colors, primary-a);
                    margin-bottom: 0;
                }
                .icon {
                    @include font-size(18);
                    margin-left: 15px;
                    color: map-get($theme-colors, primary-a);
                    transition: all .5s ease-in-out;
                }
            }
        }
    }

    &__tabs-inner {
        padding: 0 2rem;
        background-color: $white;
        display: flex;
    }

    &__accordion {
        background: #FFFFFF;
        box-shadow: 0px 0px 9px rgba(1, 121, 196, 0.48);
        border-radius: 3px;

        @include media-breakpoint-up(lg) {
            background-color: $transparent ;
            box-shadow: 0 0 0 rgba($transparent, 0);
            visibility: hidden;
            height: 0;
            opacity: 0;
            transition: opacity 1.5s ease-in-out;
        }
            
        &.active {
            visibility: visible;
            height: 100%;
            opacity: 1;
            
            @include media-breakpoint-up(lg) {
                margin: 1rem 0;
            }


            .accordion-trigger {
                &__step {
                    .icon {
                        transform: rotate(-180deg);
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 2rem;
                    height: 1px;
                    width: calc(100% - 4rem);
                    background-color: map-get($theme-colors, primary-a);
                }
            }

            .accordion-content {
                display: block;
            }
        }
            
        .accordion-trigger {
            padding: 2rem;
            position: relative;
            margin-bottom: 2rem;
            
            &__icon {
                margin-bottom: 1rem;
                width: 37px;
                height: 37px;
            }

            &__title {
                .h5 {
                    color: map-get($theme-colors, primary-b);
                }
            }
            
            &__step {
                @extend .special-tag;
                color: map-get($theme-colors, primary-a);
                display: inline-flex;
                align-items: center;
                
                .icon {
                    display: inline-flex;
                    margin: 0 8px;
                    transition: all .5s ease-in-out;
                }
            }
            
            @media (min-width: 992px) {
                display: none;
            }
        }

        .accordion-content {
            padding: 2rem;
            display: none;

            .step {
                @extend .special-tag;
                margin-bottom: 20px;
                color: map-get($theme-colors, primary-a);
            }
            
            @include media-breakpoint-up(lg) {
                display: block;
                padding: 0 2rem 2rem 2rem;
            }
        }
    }
}