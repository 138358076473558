.block.page-builder {
  padding: $page-builder-block-spacing 0;
}

// Text Block - 2 Columns
.page-builder--text-block_two-column {}

// Image with caption block
.page-builder--image-w-caption {
  &-layout {}
  &_image--wrapper {
    .background-image {
      padding-bottom: 100%;

    }
  }
}

/*
 * Banner Images
 * @see pb-banner-image-block-layout.twig
 */
.page-builder-banner--block {
  .pb--banner {
    display: block;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      width: 100%;
      transition: padding-bottom 0.5s ease;

      @include media-breakpoint-up(md) {
        padding-bottom: 56.25%;
      }
    }
  }

  &.standard {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 60%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }

  &.full-width {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 65%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }
}

/*
 * Slider
 * @see pb-page-slider-block-layout.twig
*/
.pb--slider {
  // Styling for middle arrows
  &.middle-arrows {
    .owl-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      z-index: 10;
      padding: 0 10px;

      .owl-prev,
      .owl-next {
        &::before {
          font-family: FontAwesome;
          color: #fff;
          font-size: 1rem;
          line-height: 1;
        }

        span {
          display: none;
        }
      }

      .owl-prev::before {
        content: "\f060";
      }

      .owl-next::before {
        content: "\f061";
      }
    }
  }

  &.sc-dots-styling {
    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;

      .owl-dot {
        span {
          background: map-get($theme-colors, secondary);
        }

        &.active {
          span {
            background: map-get($theme-colors, primary);
          }
        }
      }
    }
  }
}

.pb--slide {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }
}

/*
 * Featherlight Styles
*/
.with-featherlight {
  .featherlight .featherlight-content {
    border-bottom: none;
    padding: 0;
  }
}

// Content Grid Styles
.page-builder--content-grid {
  
  .grid-item {
    margin: calc(32px / 2) 0;
    padding: 1rem 0 1rem 30px;
    border-left: 4px solid map-get($theme-colors, primary-a);
    height: calc(100% - 16px);

    @include media-breakpoint-up(xl) {
      margin: calc(4.375rem / 2) 0;
      padding: 0 0 0 1.5rem;
      height: calc(100% - 35px);
    }

    .icon {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      width: 50px;
      height: 50px;
    }
  }
}

// Testimonial Slider
.page-builder--testimonial-slider {
  .testimonial-feature-block {
    padding-right: 2rem;
  }

  .testimonial-slider-container {
    .slider-control-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .slider-dots {
      display: flex;
      align-items: center;
      ul.slick-dots {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;

        li {
          padding: .25rem;
          display: flex;
          align-content: center;
          &.slick-active {
            button {
              background-color: map-get($theme-colors, primary);
            }
          }
          button {
            padding: 0;
            font-size: 0;
            width: 12px;
            height: 12px;
            border: 2px solid map-get($theme-colors, primary);
            background-color: $transparent;
            border-radius: 10px;
            cursor: pointer;
            &:focus {
              outline: 0;
            }
            &:hover {
              opacity: .6;
            }
          }
        }
      }
    }
    .slider-controls {
      .slick-arrow {
        padding: .5rem;
        cursor: pointer;
        font-size: 1.25rem;
        &:hover {
          opacity: .6;
        }
      }
    }
  }
}

// Feature Post Block
.page-builder--feature-post {
  &__post-tease {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    &.single-post {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      header {
        img {
          margin: 0;
        }
      }

      footer {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;

        @include media-breakpoint-up(lg) {
          padding: 2rem;
        }

        a.btn {
          display: inline-flex;
          align-self: baseline;
        }
      }
    }

    header {
      img {
        @include media-breakpoint-up(lg) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

// Spacer Block
.page-builder--spacer-block {
  &.small {
    padding: 2.5rem 0;
  }
  &.large {
    padding: 5rem 0;
  }
}

// Feature Logo Slider
.page-builder--logo-feature-block {
  .feature-logo {
    padding: 1rem 0;

    @include media-breakpoint-up(lg) {
      display: flex !important;
      align-items: center;
      height: 100%;
    }
    
    img {
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        margin: auto;
      }
    }
  }

  .logo-slider {
    .slick-slide {
      width: 200px !important;

      @include media-breakpoint-up(md) {
        width: 250px !important;
      }

      .feature-logo {       
        @include media-breakpoint-up(xl) {
          border-right: 1px solid rgba($black, 0.25);
        }
      }

      &:last-child {
        .feature-logo {
          @include media-breakpoint-up(xl) {
            border-right: 0;
          }
        }
      }
    }

    .slider-controls {
      display: flex;
      justify-content: flex-end;

      .slick-arrow {
          cursor: pointer;
          display: inline-flex !important;
          justify-content: center;
          align-items: center;
          margin: .5rem;
          min-width: 40px;
          min-height: 40px;
          border-radius: 20px;
          border: 2px solid rgba($black, .25);
          color: rgba($black, .25);
          line-height: 1rem;
          transition: all .25s ease-in-out;

          &:hover {
              background-color: rgba($black, .25);
              color: $white;
          }
      }
    }

    @include media-breakpoint-up(lg) {
      .slick-track {
        display: flex;
        justify-content: center;
        width: auto !important;
        transform: translate3d(0px, 0px, 0px) !important;

      }

      .slick-slide {
        height: inherit !important;

        > div {
          height: 100%;
        }
      }
    }
  }
}

// CTA Block
.page-builder--cta-block {
  position: relative;

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__background {
    position: absolute;
    z-index: -2;
    top: 0;
    width: 100%;
    height: 100%;
    @include bg-image(cover, center, no-repeat);

    &:after {
      content: '';
      padding-bottom: 100%;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    position: relative;
    z-index: 5;
    padding: 5rem 0;
  }
}
// CTA Block -- END

// PB - Team Block
.page-builder--team-block {
  .feature-staff-member {
    padding: 2rem 0;

    ul.social-links {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline-flex;
      align-items: center;

      li {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        a {
          @include font-size(25);
          &:hover {
            opacity: .6;
          }
        }
      }
    }

    .staff-title {
      font-size: 1rem;
    }
  }
}
// PB - Team Block -- END

// PB Accordion Block
.page-builder--accordion-block {

  .accordion {
    margin: 0.75rem 0;
    &.open {
      .accordion__header {
        .accordion__icon {
          transform: rotate(-180deg);
        }
      }
    }
    &__header {
      display: block;
      width: 100%;
      text-decoration: none;
      padding: 1.25rem;
      background-color: map-get($theme-colors, primary);
      font-size: 1rem;
      color: $white;
      position: relative;
      margin-bottom: 0;
      cursor: pointer;
  
      .accordion__heading {
        display: block;
        margin-right: 20px;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 60px;
      font-size: 1.25rem;
    }
    &__content {
      padding: 1.25rem;
      display: none;
      background-color: $white;
    }
  }

}
// PB Accordion Block -- END