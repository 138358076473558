.page-builder--contact-details-block {
    .location-item {
        padding: 1rem 0;
        margin-left: 1.5rem;
        position: relative;

        &-block {
            margin-bottom: 21px;

        }

        &__name {
            line-height: 26px;

            .h5 {
                margin-bottom: 0;
                line-height: 26px;
            }

            &:before {
                position: absolute;
                content: '\f041';
                font-family: "Font Awesome 5 Pro";
                font-weight: bold;
                left: -1.5rem;
            }
        }

        &__number, &__email, &__address {
            @extend .text-big;
            color: map-get($theme-colors, dark-secondary);
        }

        &__number, &__email {
            a {
                @extend .text-big;
                color: map-get($theme-colors, dark-secondary);
                &:hover {
                    text-decoration: none;
                    opacity: .7;
                }
            }
        }

        &__number {
            a {
                font-size: 1.5rem;
            }
        }
        &__email {}
        &__address {}
    }
}