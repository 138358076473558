/* ==========================================================================
    Page Header Specific Styles
   ========================================================================== */
.page-header {
  overflow: hidden;
  min-height: 400px;

  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
    
  }

  .logo-overlay {
    background-image: url('../images/hx-white-overlay.svg');
    @include bg-image(contain, center, no-repeat);
    position: absolute;
    z-index: -1;
  }
}

.standard-page-header {
  padding: 80px 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-end;

  @include media-breakpoint-up(md) {
    min-height: 400px;
    padding-top: 120px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 30rem;
    padding-top: 180px;
  }

  .logo-overlay {
    width: 430px;
    height: 287px;
    bottom: 40%;
    right: -50%;

    @include media-breakpoint-up(xl) {
      width: 1070px;
      height: 590px;
      bottom: -10%;
      right: -10%;
    }
  }
  // Contact form
  .header-form-card {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
  }
}

// Front Page Header Styles
.front-page-header {
  @include media-breakpoint-up(md) {
    min-height: 600px;
  }

  .logo-overlay {
    width: 430px;
    height: 287px;
    bottom: 40%;
    right: -50%;

    @include media-breakpoint-up(xl) {
      width: 1070px;
      height: 590px;
      bottom: -10%;
      right: -10%;
    }
  }
}

.front-page-slider {
  .slide {
    .front-page-slider {
      &__slide {
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;

        &:after {
          content: '';
          display: block;
          padding-bottom: 150%;

          @include media-breakpoint-up(md) {
            padding-bottom: 90%;
          }
          @include media-breakpoint-up(lg) {
            padding-bottom: 75%;
          }
          @include media-breakpoint-up(xl) {
            padding-bottom: 40%;//37.239%;
          }
        }

        .slide-content {
          height: 100%;
          width: 100%;
          position: relative;
          z-index: 3;
        }

        .slide-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include bg-image(cover, center, no-repeat);
          z-index: -5;

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(73.58deg, rgba(3, 3, 2, 0.696) 25.64%, rgba(3, 6, 14, 0.255421) 49.58%, rgba(7, 12, 30, 0) 65%);
            background-blend-mode: darken, normal;
          }
        }
      }
    }
  }

  .slider-dots-wrapper {
    width: 100%;
    position: absolute;
    z-index: 10;
    bottom: 20%;
  }

  .slider-dots {
    display: flex;
    justify-content: flex-end;
    
    ul.slick-dots {
      list-style: none;
      padding: 0;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      margin-top: 36px;
      
      li {
          display: flex;
          align-content: center;
          padding: .25rem;

          &.slick-active,
          &:hover {
              button {
                  background-color: $white;
                  border-color: $transparent;
              }
          }

          button {
              padding: 0;
              font-size: 0;
              width: 13px;
              height: 13px;
              border: 2px solid $white;
              background-color: $transparent;
              border-radius: 7px;
              transition: all .5s ease-in-out;

              &:focus, &:active {
                  outline: 0;
                  background-color: $white;
                  border-color: $transparent;
              }
          }
      }
    }
  }
}
// Front Page Header Styles -- END