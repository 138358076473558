.page-builder--contact-form-block {
    &__caption {
        padding: 2rem 1rem;
        // height: 100%;
        display: inline-flex;
        align-items: center;
    }
}

.page-builder--contact-form-block__caption {
    &.with-bg {
        background-image: url(../images/image-masks/review.svg);
        @include bg-image(cover, top, no-repeat);
        border-radius: 5px;
        min-height: 300px;
    }
}