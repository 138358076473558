.tool-pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .pages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 0;
    margin: 0;

    .pages-item {
      margin: 0 10px;
    }

    .pages-link {

    }
  }
}