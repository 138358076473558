.tag-block {
    @include font-size(14);
    @include defaultHeadingFont();
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1rem;
    
    padding: .5rem 1rem;
    border-radius: 2px;

    &--white {
        background-color: rgba($white, .5);
        color: $white;
    }

    &--black {
        background-color: rgba($black, .25);
        color: $white;
    }
}