.btn {
  @include buttonText();
  border-radius: 0;
  position: relative;
  cursor: pointer;
  padding: 13px 36px;
  margin: .25rem;
  transition: all .25s ease-in-out;  
  color: map-get(map-get($button-colors, standard), base-color);
  background-color: map-get(map-get($button-colors, standard), base-background);
  border: 3px solid map-get(map-get($button-colors, standard), base-color);

  @include media-breakpoint-up(lg) {
    padding: .5rem 2.5rem;
    height: 44px;
    line-height: 22px;
  }

  &:hover {
    color: map-get(map-get($button-colors, standard), hover-color);
    background-color: map-get(map-get($button-colors, standard), hover-background);
    border-color: map-get(map-get($button-colors, standard), hover-background);
  }

  &-icon {
    &:after {
      position: absolute;
      right: 15px;
      
    }
  
    &--arrow-right {
      &:after {
        content: '\f178';
        font-family: "Font Awesome 5 Pro";
      }
    }
    &--arrow-left {
      &::after {
        content: '\f177';
        font-family: "Font Awesome 5 Pro";
      }
    }
    &--calendar {
      &:after {
        content: '\f133';
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
      }
    }

    &--mobile {
      &:after {
        content: '\f10b';
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
      }
    }

    &--comment {
      &:after {
        content: '\f075';
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
      }
    }

    &--calendar {
      &:after {
        content: '\f133';
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
      }
    }

    &--envelope {
      &:after {
        content: '\f0e0';
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
      }
    }

    &--phone {
      &:after {
        content: '\f095';
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
      }
    }
  
    &--chevron-right {
      &:after {
        content: '\f054';
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
      }
    }

    &--chevron-left {
      &:after {
        content: '\f053';
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
      }
    }

    &--float-left {
      &:after {
        left: 15px !important;
        right: initial;
      }
    }
    &--float-right {
      &:after {
        right: 15px !important;
        left: initial;
      }
    }
  }

  &-primary {
    color: map-get(map-get($button-colors, primary), base-color);
    background-color: map-get(map-get($button-colors, primary), base-background);
    border: 3px solid map-get(map-get($button-colors, primary), base-color);

    &:hover {
      color: map-get(map-get($button-colors, primary), hover-color);
      background-color: map-get(map-get($button-colors, primary), hover-background);
      border-color: map-get(map-get($button-colors, primary), base-background);
    }

    &:focus, &:active {
      box-shadow: 0 0 0 0 $transparent !important;
      background-color: map-get(map-get($button-colors, primary), hover-background);
      border-color: map-get(map-get($button-colors, primary), base-background);
    }

    &__alt {
      box-shadow: 3px 3px 3px #ccc;
    }

    &--text,
    &--text-alt {
      border: 0;
      padding-left: 0;
      padding-right: 0;
      transition: all .5s ease-in-out;
      line-height: auto;
      height: auto;

      .icon {
        margin-left: .25rem;
        transition: all .5s ease-in-out;
      }

      &:hover {
        color: darken(map-get(map-get($button-colors, primary), base-color), 5%);
        background-color: $transparent;

        .icon {
          margin-left: .5rem;
        }
      }

      &:focus, &:active {
        box-shadow: 0 0 0 0 $transparent !important;
        background-color: $transparent;
      }
    }
  }

  &-secondary {
    color: map-get(map-get($button-colors, secondary), base-color);
    background-color: map-get(map-get($button-colors, secondary), base-background);
    border: 3px solid map-get(map-get($button-colors, secondary), base-color);

    &:hover {
      color: map-get(map-get($button-colors, secondary), hover-color);
      background-color: map-get(map-get($button-colors, secondary), hover-background);
      border-color: map-get(map-get($button-colors, secondary), base-background);
    }

    &:focus, &:active {
      box-shadow: 0 0 0 0 $transparent !important;
      background-color: map-get(map-get($button-colors, secondary), hover-background);
      border-color: map-get(map-get($button-colors, secondary), base-background);
    }

    &__alt {
      box-shadow: 3px 3px 3px #ccc;
    }

    &--text,
    &--text-alt {
      border: 0;
      padding-left: 0;
      padding-right: 0;
      transition: all .5s ease-in-out;
      line-height: auto;
      height: auto;

      .icon {
        margin-left: .25rem;
        transition: all .5s ease-in-out;
      }

      &:hover {
        color: darken(map-get(map-get($button-colors, secondary), base-color), 5%);
        background-color: $transparent;

        .icon {
          margin-left: .5rem;
        }
      }

      &:focus, &:active {
        box-shadow: 0 0 0 0 $transparent !important;
        background-color: $transparent;
      }
    }
  }

  &-white-outline {
    color: map-get(map-get($button-colors, secondary-solid), base-color);
    background-color: map-get(map-get($button-colors, white-ouline), base-background);
    border: 3px solid map-get(map-get($button-colors, white-ouline), base-color);

    &:hover {
      color: map-get(map-get($button-colors, white-ouline), hover-color);
      background-color: map-get(map-get($button-colors, white-ouline), hover-background);
      border-color: map-get(map-get($button-colors, white-ouline), base-background);
    }

    &:focus, &:active {
      box-shadow: 0 0 0 0 $transparent !important;
      background-color: map-get(map-get($button-colors, white-ouline), hover-background);
      border-color: map-get(map-get($button-colors, white-ouline), base-background);
    }

    &__alt {
      box-shadow: 3px 3px 3px #ccc;
    }

    &--text,
    &--text-alt {
      border: 0;
      padding-left: 0;
      padding-right: 0;
      transition: all .5s ease-in-out;

      .icon {
        margin-left: .25rem;
        transition: all .5s ease-in-out;
      }

      &:hover {
        color: darken(map-get(map-get($button-colors, white-ouline), base-color), 5%);
        background-color: $transparent;

        .icon {
          margin-left: .5rem;
        }
      }
    }
  }

  &-primary-solid {
    color: map-get(map-get($button-colors, primary-solid), base-color);
    background-color: map-get(map-get($button-colors, primary-solid), base-background);
    border: 3px solid map-get(map-get($button-colors, primary-solid), base-background);

    &:hover {
      color: map-get(map-get($button-colors, primary-solid), hover-color);
      background-color: map-get(map-get($button-colors, primary-solid), hover-background);
      border-color: map-get(map-get($button-colors, primary-solid), base-background);
    }

    &:focus {
      box-shadow: 0 0 0 0 $transparent;
    }

    &__alt {
      box-shadow: 3px 3px 3px #ccc;
    }

    &--text,
    &--text-alt {
      border: 0;
      padding-left: 0;
      padding-right: 0;
      transition: all .5s ease-in-out;

      .icon {
        margin-left: .25rem;
        transition: all .5s ease-in-out;
      }

      &:hover {
        color: darken(map-get(map-get($button-colors, primary-solid), base-color), 5%);
        background-color: $transparent;

        .icon {
          margin-left: .5rem;
        }
      }
    }
  }

  &-secondary-solid {
    color: map-get(map-get($button-colors, secondary-solid), base-color);
    background-color: map-get(map-get($button-colors, secondary-solid), base-background);
    border: 3px solid map-get(map-get($button-colors, secondary-solid), base-background);

    &:hover {
      color: map-get(map-get($button-colors, secondary-solid), hover-color);
      background-color: map-get(map-get($button-colors, secondary-solid), hover-background);
      border-color: map-get(map-get($button-colors, secondary-solid), hover-background);
    }

    &:focus {
      box-shadow: 0 0 0 0 $transparent;
    }

    &__alt {
      box-shadow: 3px 3px 3px #ccc;
    }

    &--text,
    &--text-alt {
      border: 0;
      padding-left: 0;
      padding-right: 0;
      transition: all .5s ease-in-out;

      .icon {
        margin-left: .25rem;
        transition: all .5s ease-in-out;
      }

      &:hover {
        color: map-get(map-get($button-colors, secondary-solid), base-color);
        background-color: $transparent;

        .icon {
          margin-left: .5rem;
        }
      }
    }
  }

}

.text-link {
  @include buttonText();
  display: flex;
  transition: all .25s ease-in-out;

  &:hover {
    text-decoration: none;

    .icon {
      transform: translate3d(-5px, 0, 0);
    }
  }

  &-primary-a {
    color: map-get($theme-colors, primary-a);
  }

  .icon {
    margin-right: 8px;
    margin-left: 8px;
    font-weight: normal;
    transition: all .25s ease-in-out;
  }

  &-standard {}
}