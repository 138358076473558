/* ==========================================================================
   General Utility Animations
   ========================================================================== */
// @include keyframes(fade_in) {
//     from {
//         opacity: 0;
//     }

//     to {
//         opacity: 1;
//     }
// }

// @include keyframes(fade_out) {
//     from {
//         opacity: 1;
//     }

//     to {
//         opacity: 0;
//     }
// }

/* ==========================================================================
   Navigation Keyframe Animations
   ========================================================================== */
// @include keyframes(nav_in) {
//     from {
//         transform: translate3d(0, 0, 0);
//     }

//     to {
//         transform: translate3d(285px, 0, 0);
//     }
// }

// @include keyframes(nav_out) {
//     from {
//         transform: translate3d(285px, 0, 0);
//     }

//     to {
//         transform: translate3d(0, 0, 0);
//     }
// }

/* ==========================================================================
   Animate.css
   ========================================================================== */
.activate {
	opacity: 1;
}
.animated {
	opacity: 0;
}