.page-builder--testimonial-slider-block {
    &__cta-block {
        padding: 1rem;
        width: 100%;
        height: 100%;
        overflow: hidden;
        min-height: 250px;
        background-image: url('../images/image-masks/review.svg');
        @include bg-image(cover, right, no-repeat);
        // background-repeat: no-repeat;
        // background-size: 100%;
        // background-position: right;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;

        @include media-breakpoint-up(lg) {
            padding: 3rem 1rem;
            height: auto;
        }
    }
    .testimonial-slider-container {
        // @include media-breakpoint-up(xl) {
            .slick-list {
                padding: 0 25px !important;
                &:before, &:after {
                    content: '';
                    width: 15%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    z-index: 2;
                }
                &:before {
                    left: 0;
                    top: 0;
                    background: linear-gradient(90deg, $white, $transparent);
                }
                &:after {
                    right: 0;
                    top: 0;
                    background: linear-gradient(-90deg, $white, $transparent);
                }
            }
        // }

        .slick-slide {
            padding: 2rem .75rem;
            // width: 300px !important;

            @include media-breakpoint-up(md) {
                padding: 2rem 1rem;
                // width: 530px !important;
                // padding: 2rem 2.5rem;
            }

            @include media-breakpoint-up(xl) {
                width: 530px !important;
            }

            .rating {
                display: inline-flex;
                padding-bottom: 1rem;
                &__star {
                    padding: 0 .25rem;
                    color: map-get($theme-colors, primary-a);

                    i {
                        font-weight: 300;
                    }
                }
            }
        }

        .slider-controls {
            display: flex;
            justify-content: flex-end;

            .slick-arrow {
                cursor: pointer;
                display: inline-flex !important;
                justify-content: center;
                align-items: center;
                margin: .5rem;
                min-width: 40px;
                min-height: 40px;
                border-radius: 20px;
                border: 2px solid rgba($black, .25);
                color: rgba($black, .25);
                line-height: 1rem;
                transition: all .25s ease-in-out;

                &:hover {
                    background-color: rgba($black, .25);
                    color: $white;
                }
            }
        }
    }
}