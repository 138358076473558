.feature-news-post-block {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
    &__feature-image {
        position: relative;

        .chamfer-block {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 495px;
            }

            .mask {
                mask-position: top;
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;

        .tag-block {
            margin-bottom: 26px;
        }
    }
}