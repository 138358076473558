body.post-type-archive-careers {
    .careers-listing-wrapper {
        .row {
            .col-12 {
                border-bottom: 1px solid map-get($theme-colors, dark-disabled);

                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }
    }
}

.careers-tease {
    &__posted {
        margin-bottom: 1.5rem;
    }
}