.page-builder--statistic-block {
    &__stat {
        margin: 1rem;
        width: calc(100% - 2rem);

        // @include media-breakpoint-up(md) {
        //     width: calc(50% - 2rem);
        // }
        // @include media-breakpoint-up(lg) {
        //     width: calc(33.33% - 2rem);
        // }
        // @include media-breakpoint-up(xl) {
        //     width: calc(100% - 2rem);
        // }
    }

    .statblock-slider-container {
        .slider-dots {
            width: 100%;
            display: inline-flex;
            justify-content: center;

            ul.slick-dots {
                list-style: none;
                padding: 0;
                margin: 0;
                display: inline-flex;
                justify-content: center;
                margin-top: 36px;
                
                li {
                    display: flex;
                    align-content: center;
                    padding: .25rem;

                    &.slick-active,
                    &:hover {
                        button {
                            background-color: rgba($black, .25);
                            border-color: $transparent;
                        }
                    }

                    button {
                        padding: 0;
                        font-size: 0;
                        width: 13px;
                        height: 13px;
                        border: 2px solid rgba($black, .25);
                        background-color: $transparent;
                        border-radius: 7px;
                        transition: all .5s ease-in-out;

                        &:focus, &:active,  {
                            outline: 0;
                            background-color: rgba($black, .25);
                            border-color: $transparent;
                        }
                    }
                }
            }
        }
    }
}