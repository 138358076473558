.sticky-single-menu {
    background-color: map-get($theme-colors, secondary-a);
    display: flex;
    align-items: center;
    height: 75px;
    z-index: 15;
    position: fixed;
    width: 100%;
    bottom: 0;
    transform: translate3d(0, 10px, 0);
    opacity: 0;
    visibility: hidden;
    transition: all .35s ease-in-out;

    &.active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }

    @include media-breakpoint-up(lg) {
        height: auto;
        background-color: map-get($theme-colors, primary-b);
    }

    @include media-breakpoint-up(xl) {
        height: 75px;
    }

    &__overview, &__button {
        margin: 0 35px;
    }

    &__logo {
        display: block;
        width: 89px;
        height: 63px;
    }

    &__overview {
        display: flex;
        align-items: center;

        a {
            @include defaultH5();
            @include font-size(15);
            font-weight: bold;
            color: map-get($theme-colors, primary-a);
            margin-bottom: 0;
            text-decoration: none;
            transition: all .25s ease-in-out;

            &.active-link {
                color: map-get($theme-colors, dark-disabled);

                @include media-breakpoint-up(lg) {
                    color: $white;
                }
            }

            &:hover {
                opacity: .7;
            }
        }
    }

    &__dropdown {
        .bootstrap-select {

            &.show {
                .dropdown-toggle:after {
                    transform: rotate(0deg);
                }
            }

            &.dropup {
                .dropdown-toggle:after {
                    transform: rotate(0deg);
                }
            }

            .dropdown-toggle {
                border: 0;
                transition: all .25s ease-in-out;

                &.active-dropdown {
                    &:after {
                        color: map-get($theme-colors, dark-disabled);
                    }

                    @include media-breakpoint-up(lg) {
                        &:after {
                            color: $white;
                        }
                    }
                }

                &:after, .filter-option-inner-inner {
                    color: map-get($theme-colors, primary-a);

                    @include media-breakpoint-up(lg) {
                        color: map-get($theme-colors, primary-a);
                    }
                }

                .filter-option-inner-inner {
                    @include defaultH5();
                    margin-bottom: 0;

                    &.active-dropdown {
                        color: map-get($theme-colors, dark-disabled);

                        @include media-breakpoint-up(lg) {
                            color: $white;
                        }
                    }
                }

                &:after {
                    // margin-left: 20px;
                    width: auto !important;
                    height: auto !important;
                    transform: rotate(180deg);

                }

                &:focus {
                    outline: 0 !important;
                    box-shadow: 0 0 0 0 $transparent !important;
                }

                &:hover {
                    opacity: .7;
                }
            }

            div.dropdown-menu {
                display: block;
                opacity: 0;
                visibility: hidden;
                z-index: -1;
                transition: opacity .5s ease-in-out;
                background-color: $white;
                box-shadow: 0 4px 8px rgba(0,0,0,.1);
                padding: 30px 0;

                &.show {
                    opacity: 1;
                    visibility: visible;
                    z-index: 3;
                }

                ul.dropdown-menu {
                    li {
                        a {
                            &.active, &:active {
                                background-color: $transparent;

                                .text {
                                    color: map-get($theme-colors, primary-b);
                                }
                            }
                            .text {
                               @include defaultH5();
                               margin-bottom: 0;
                               color: map-get($theme-colors, primary-a);
                            }
                        }
                    }
                }
            }
        }
    }

    &__button {
        .icon {
            margin-right: 17px;
        }
    }
}