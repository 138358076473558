/////////////////////////////////////////////
//** Variables **//
//** This is the file for basic overrides of bootstraps variables. **//
//** You can accomplish a lot of the site setup here. If you need access to more, checkout bootstrap_components/bootstrap/ and find the variables file. **//
/////////////////////////////////////////////


// This can be removed, purely for starter theme styling
$sc-gradient: linear-gradient(90deg, #43E695 0%, #3BB2B8 100%);

// Border Radius Settings
//
// This defines the border radius globally across elements including buttons.
$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

/*
 * Color System
 */
$white:    #fff;
$gray:     #373737;
$gray-alt: #F3F3F3;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
$transparent: rgba(255, 255, 255, 0);

$grays: (
        100: $gray-100,
        200: $gray-200,
        300: $gray-300,
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900
) !default;

// Colors
//
// Regular Colors
$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #F26522;
$yellow:  #ffc107;
$green:   #8dc63f;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
        blue:      $blue,
        indigo:    $indigo,
        purple:    $purple,
        pink:      $pink,
        red:       $red,
        orange:    $orange,
        yellow:    $yellow,
        green:     $green,
        teal:      $teal,
        cyan:      $cyan,
        white:     $white,
        gray:      $gray,
        gray-dark: $gray-800
) !default;

// Colors
//
// Theme Colors
$footer-bg-color: #000f36;

$theme-colors: (
        primary: #017BC4,
        primary-a: #017BC4,
        primary-b: #041939,
        primary-c: #444946,
        secondary: #EBEEF2,
        secondary-a: #EBEEF2,
        secondary-b: #1696F2,
        success: #6FCF97,
        info: #F2C94C,
        error: #E52828,
        danger: #E52828,
        dark-primary: #000000,
        dark-secondary: #5A5A5A,
        dark-disabled: #AEAEAE,
        light-primary: #FFFFFF,
        light-secondary: #CDCDCD,
        light-disabled: #828282
) !default;

// Colors
// Button Colors
// @see _buttons.scss
$button-colors: (
        standard: (
                base-background: $transparent,
                base-color: map-get($theme-colors, primary-b),
                hover-background: map-get($theme-colors, primary-b),
                hover-color: $white,
        ),
        primary: (
                base-background: $transparent,
                base-color: map-get($theme-colors, primary-b),
                hover-background: map-get($theme-colors, primary-b),
                hover-color: $white
        ),
        secondary: (
                base-background: $transparent,
                base-color: map-get($theme-colors, primary-a),
                hover-background: map-get($theme-colors, primary-a),
                hover-color: $white
        ),
        white-ouline: (
                base-background: $transparent,
                base-color: $white,
                hover-background: darken($white, 15%),
                hover-color: map-get($theme-colors, primary-b)
        ),
        primary-solid: (
                base-background: map-get($theme-colors, primary-b),
                base-color: $white,
                hover-background: darken(map-get($theme-colors, primary-b), 15%),
                hover-color: $white
        ),
        secondary-solid: (
                base-background: map-get($theme-colors, primary-a),
                base-color: $white,
                hover-background: darken(map-get($theme-colors, primary-a), 15%),
                hover-color: $white
        )
);

// Button Settings
//
// Padding
//
// For each of Bootstrap's buttons, define text, background and border color.

// Standard Buttons
$input-btn-padding-y:       0.6875rem; // 11px
$input-btn-padding-x:       2.5rem; // 40px
$input-btn-line-height:     1; // 1 line height

// Small Buttons
$input-btn-padding-y-sm:    .25rem;
$input-btn-padding-x-sm:    .5rem;
$input-btn-line-height-sm:  1.5;

// Large Buttons
$input-btn-padding-y-lg:    .75rem; // 12px
$input-btn-padding-x-lg:    2.5rem; // 40px
$input-btn-line-height-lg:  1.5;

$btn-block-spacing-y:            .5rem !default;
// $btn-font-weight:                $font-weight-normal;

// Button Settings
//
// Border Radius
//
// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;

$btn-transition:                 all .15s ease-in-out;


// Grid containers
//
// Container max widths

$container-max-widths: (
       sm: 540px,
       md: 720px,
       lg: 960px,
       xl: 1140px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff;
$body-color: #000;

// Links
//
// Style anchor elements.
$link-color: map-get($theme-colors, primary-a);
$navigation-link-color: map-get($theme-colors, primary-a);
$link-decoration: none !default;
$link-hover-color: map-get($theme-colors, primary-b) !default;

/*
 * Fonts
 *
 * Font, line-height, and color for body text, headings, and more.
 */

// Font Families
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-roboto: 'Roboto', $font-family-open-sans;
$font-family-base:       $font-family-sans-serif;

$font-family-primary: 'Forza', sans-serif;
$font-family-secondary: 'PT Sans', sans-serif;

// Font Sizes
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1.125rem; // 18px 
$font-size-sm:   .875rem; // 14px

// $h1-large:       4rem;

// Headings
$headings-font-family:   $font-family-roboto;
$headings-font-weight:   100;
$headings-color:         map-get($theme-colors, primary);
$headings-margin-bottom: 20px;

// Font Weights
$font-weight-normal: normal;
$font-weight-bold:   bold;
$font-weight-base:   $font-weight-normal;
$line-height-base:   1.6666666667;
$headings-line-height:   1.2;

/**
 * Font Sizes
 */
$h1-font-size: 4.375rem; // 70px
$h2-font-size: 3.75rem; // 60px
$h3-font-size: 2.25rem;  // 36px
$h4-font-size: 1.75rem; // 28px
$h5-font-size: 1.5rem; // 24px
$h6-font-size: 1.25rem; // 20px

$spacer: 1.25rem;

/**
 * Page Builder Settings
 */
$page-builder-block-spacing: 60px;

/**
 *  Menu Animations
 */
$menu-animation-timing: 0.5s ease-in-out;
$mobile-menu-width: 307px;

$primary-nav-padding: 15px;