// Default Bootstrap Select Styles

// Select Dropdown - Width
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100% !important;
    height: auto;
    min-height: 40px;
}

// Button Style
.bootstrap-select > .dropdown-toggle {
    @include font-size(18);
    width: 100%;
    min-height: 40px;
    height: auto;
    background-color: $transparent;
    border: 0;    
    border-bottom: 4px solid map-get($theme-colors, primary);
    background-color: $transparent;
    color: map-get($theme-colors, primary-b);
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    padding: 17px 8px !important;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    font-family: $font-family-secondary;
    font-weight: normal;
    white-space: pre-line;
    margin-right: .5rem;
}

// Button - Active Style
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
    border-color: map-get($theme-colors, primary);
    background-color: $transparent;
}

// Button - Arrow
.dropdown-toggle:after, .dropup .dropdown-toggle:after {
    border: 0;
    @include font-size(14);
    content: '\f077';
    font-family: "Font Awesome 5 Pro";
    color: map-get($theme-colors, primary-b);
    font-weight: normal;
}

.dropdown-toggle:after {
    @include font-size(14);
    content: '\f078';
    font-family: "Font Awesome 5 Pro";
    color: map-get($theme-colors, primary-b);
    font-weight: normal;
    height: 100% !important;
}
// Button - Arrow -- END

// Dropdown Styles
.dropup .dropdown-menu {
    border-radius: 0 !important;
    border: 0;
    padding: 0;
}

.bootstrap-select .dropdown-menu {
    border-radius: 0;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: map-get($theme-colors, primary);
}

.bootstrap-select .dropdown-menu li.active a {
    color: $white;
}

.bootstrap-select .dropdown-menu li {
    a {
        .text {
            margin-bottom: 0;
        }
    }
    &:focus, &:active {
        a {
            color: $white;
            background-color: map-get($theme-colors, primary);

            
        }
    }
}