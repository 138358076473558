.team-block {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    &__bio-image {}
    &__content {
        &:not(.team-block--medium) {
            padding: 1rem;
        }
        
        width: 100%;

        .heading {
            margin-bottom: .75rem;
        }
        .job-title {
            margin-bottom: .75rem;
        }

        ul.social-links {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin: .5rem;
                &:first-child {
                    margin-left: 0;
                }
                a {
                    @include font-size(17);
                }
            }
        }
    }


    &--medium {
        flex-direction: column;

        .team-block {
            &__bio-image {

                @include media-breakpoint-up(md) {
                    width: 50%;
                }

                @include media-breakpoint-up(xl) {
                    width: auto;
                }

                .chamfer-block {
                    width: 100%;
                    height: 350px;

                    @include media-breakpoint-up(xl) {
                        width: 198px;
                        height: 242px;
                    }
                }
            }
            
            &__content {
                padding: 1rem 0;
                
                @include media-breakpoint-up(xl) {
                    padding: 1rem;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
    }
    &--small {}
}
