.news-post-list-tease {
    position: relative;
    transition: all .25s ease-in-out;
    border-radius: 5px;
    margin: 1rem 0;
    
    &__tag {
        z-index: 2;
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
    &__content {
        z-index: 2;
        position: absolute;
        bottom: 0;
        padding: 1rem;
        border-radius: 5px;
        width: calc(100% - 1px);
        background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,rgba(0,0,0,.9));

        h3 {
            margin-bottom: .25rem;
        }
    }
    &__background {
        z-index: -1;
        
        .chamfer-block {
            width: 100%;
        }
    }

    &:hover {
        cursor: pointer;
        transform: translate3d(0, -.5rem, 0);
    }
}