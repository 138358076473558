.page-builder--cta-large {
    position: relative;
    padding: 5rem 0;
    margin: 2rem 0;
    
    
    @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;
        min-height: 700px;
    }

    &__content-wrapper {
        &.icon-left {
            margin: 65% 1rem 0 1rem;
            padding-top: 5rem;

            @include media-breakpoint-up(md) {
                margin: 50% 1rem 0 1rem;
            }

            @include media-breakpoint-up(lg) {
                margin: 60% 1rem 0 1rem;
            }

            @include media-breakpoint-up(xl) {
                margin: 0 0 0 50%;
                padding-top: 0;
            }
        }
        &.icon-right {
            margin: 50% 1rem 0 1rem;
            padding-top: 5rem;

            @include media-breakpoint-up(md) {
                margin: 35% 1rem 0 1rem;
            }

            @include media-breakpoint-up(lg) {
                margin: 50% 1rem 0;
            }

            @include media-breakpoint-up(xl) {
                margin: 0 50% 0 0;
                padding-top: 0;
            }
        }
    }

    &__logo-block {
        &.icon-left {
            position: absolute;
            top: 5rem;
            left: 50%;
            transform: translateX(-50%);

            @include media-breakpoint-up(xl) {
                top: 50%;
                transform: translate(-110%, -50%);
            }
        }
        &.icon-right {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(0%);

            @include media-breakpoint-up(xl) {
                top: 50%;
                right: 50%;
                transform: translate(110%, -50%);
            }
        }        
    }
}